<template>
    <div style="display:block">
        <van-icon name="down" v-if="status == 0" color="green" />
        <van-icon name="down" v-if="status == 2" color="red" style="transform:rotate(180deg);" />
        <van-icon name="minus" v-if="status == 1" color="#1D99F9" />
    </div>
</template>

<script>
export default {
    name: 'EyeTrend',
    props: ['status'],
    data() {
        return {

        };
    },

    mounted() {

    },

    methods: {

    },
};
</script>

<style lang="scss" scoped>
</style>