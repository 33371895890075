<template>
    <div class="content-box vision-box" style="background-color: #fff">
        <v-Header v-if="!isShow" :isShowTitle="true" :showback="true" tittle="黄斑检测" @back="$router.go(-1)">
        </v-Header>
        <div class="page-content" :class="!isShow==true?'hasTitle':''" style="background-color: #fff">
            <div class="user-box">
                <div>
                    <img src="@/assets/imgs/hbleft.png" />
                </div>
                <div class="info">
                    <div style="font-size: 0.4rem">{{ userName || '-' }}</div>
                    <div style="color: #979797">{{ $route.query.date }}</div>
                </div>
            </div>
            <div style="width: 40%">
                <van-tabs :active="active" @click="onClick" color="#1D99F9">
                    <van-tab title="右眼"></van-tab>
                    <van-tab title="左眼"></van-tab>
                </van-tabs>
            </div>
            <div v-if="currentData.length > 0" style="margin: 20px">
                <div style="text-align: left; margin-bottom: 15px; font-size: 0.4rem">
                    {{ currentData[currentIndex].No | getTitle }}
                </div>
                <history-rotation :imgs="currentData" @changeHandel="changeHandel"></history-rotation>
            </div>
            <div v-else style="margin: 200px auto">暂无检查数据</div>
            <div v-if="!isShow" class="tip-box">
                <!-- <div class="tip">
                    <span style="margin-left: 8px; font-weight: bold">温馨提示</span>
                </div>
                <div>
                    <div class="item">
                        <div class="round"></div>
                        <span style="margin-left: 10px">您的右眼模糊区域</span>
                    </div>
                    <div class="item">
                        <div class="round"></div>
                        <span style="margin-left: 10px">您的右眼模糊区域</span>
                    </div>
                </div> -->
            </div>
            <div v-if="isShow" style="margin-top:1.5rem; margin-bottom: 1.5rem;">
                <van-row :gutter="20" style="margin-bottom: 10px;">
                    <van-col :span="6"></van-col>
                    <van-col :span="6" style="text-align:left">弯曲变形</van-col>
                    <van-col :span="6" style="text-align:left">模糊不清</van-col>
                    <van-col :span="6" style="text-align:left">黑影遮挡</van-col>
                </van-row>
                 <van-row :gutter="20">
                    <van-col :span="6" style="text-align:right">右眼</van-col>
                    <van-col :span="6" style="display:flex">{{ data.right.find(e => e.No == 2) | getArea }}mm²<Trend :status="data.right.find(e => e.No == 2) && data.right.find(e => e.No == 2).Trend || 0"></Trend></van-col>
                    <van-col :span="6" style="display:flex">{{ data.right.find(e => e.No == 3) | getArea }}mm²<Trend :status="data.right.find(e => e.No == 2) && data.right.find(e => e.No == 3).Trend || 0"></Trend></van-col>
                    <van-col :span="6" style="display:flex">{{ data.right.find(e => e.No == 4) | getArea }}mm²<Trend :status="data.right.find(e => e.No == 2) && data.right.find(e => e.No == 4).Trend || 0"></Trend></van-col>
                </van-row>
                <van-row :gutter="20" style="margin-top: 10px">
                    <van-col :span="6"  style="text-align:right">左眼</van-col>
                    <!-- Trend 1 正常 2高  0 偏低-->
                    <van-col :span="6" style="display:flex">{{ data.left.find(e => e.No == 2) | getArea }}mm² <Trend :status="data.right.find(e => e.No == 2) && data.left.find(e => e.No == 2).Trend || 0"></Trend></van-col>
                    <van-col :span="6" style="display:flex">{{ data.left.find(e => e.No == 3) | getArea }}mm² <Trend :status="data.right.find(e => e.No == 2) && data.left.find(e => e.No == 3).Trend || 0"></Trend></van-col>
                    <van-col :span="6" style="display:flex">{{ data.left.find(e => e.No == 4) | getArea }}mm² <Trend :status="data.right.find(e => e.No == 2) && data.left.find(e => e.No == 4).Trend || 0"></Trend></van-col>
                </van-row>
               
            </div>
        </div>
    </div>
</template>

<script>
import vHeader from "../components/header.vue";
import HistoryRotation from "../components/historyRotation.vue";
import Trend from "../components/trend.vue";
export default {
    components: {
    vHeader,
    HistoryRotation,
    Trend
},
    name: "EyeHistoryItem",

    data() {
        return {
            active: "左眼",
            data: { left: [], right: [] },
            currentData: [],
            currentIndex: 0,
            isShow: false,
            userName:null
        };
    },
    filters: {
        getArea(obj) {
            if (typeof (obj) == 'object') {
                if (Object.keys(obj).includes('Area')) {
                    return obj.Area
                } else {
                    return 0;
                }
            } else {
                return 0;
            }

        },
        getTitle(tag) {
            switch (tag) {
                case 0:
                    return "无异常";
                case 2:
                    return "弯曲变形";
                case 3:
                    return "模糊不清";
                case 4:
                    return "黑影遮挡";
            }
        },
    },
    created() {
        this.isShow = this.$route.query.h || false;
        this.$vlf.getItem('userName').then((res)=>{
            this.userName=res
        })
    },
    mounted() {
        this.$vlf.getItem("currentHBItem").then((res) => {
            let left = [];
            let right = [];
            res.forEach((element) => {
                if (element.EyeType == 1) {
                    left.push(element);
                } else {
                    right.push(element);
                }
            });
            this.data = {
                left: left,
                right: right,
            };
            this.currentData = this.data.right;
        });
    },

    methods: {
        changeHandel(e) {
            // console.log(e);
            this.currentIndex = e;
        },
        onClick(e) {
            if (e == 0) {
                this.currentData = this.data.right;
            } else {
                this.currentData = this.data.left;
            }
            console.log(this.currentData);
        },
    },
};
</script>

<style lang="less" scoped>
@import "../assets/less/vision.less";

.user-box {
    display: flex;
    margin: 15px;
    height: 40px;
    align-items: center;

    .info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        margin-left: 15px;
        height: 40px;
    }
}

.tip-box {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    margin: 15px;
    .mt(60);

    .tip {
        margin: 0px;
        padding: 0px;
        font-weight: bold;
        font-size: 0.4rem;

        &::before {
            padding: 0px;
            content: "";
            border: 1px solid #0090ff;
        }
    }

    .item {
        position: relative;
        margin: 8px auto;
        display: flex;
        align-items: center;

        span {
            font-size: 0.4rem;
        }

        .round {
            display: inline-block;
            background-color: #0090ff;
            width: 6px;
            height: 6px;
            border-radius: 3px;
        }
    }
}
</style>
