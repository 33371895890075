<template>
  <div class="home">
    <div class="box">
      <img v-for="(item, index) in imgs" v-show="listIndex === index" :key="index" :src="item | getImgUrl" alt=""
         />
      <div class="left" @click="changePage(prevIndex)">
        <van-icon name="arrow-left" />
      </div>
      <ul>
        <li :class="{ color: index == listIndex }" v-for="(item, index) in imgs" @click="changePage(index)"
          :key="index"></li>
      </ul>
      <div class="right" @click="changePage(nextIndex)">
        <van-icon name="arrow" />
      </div>
    </div>
  </div>
</template>
 
<script>
export default {
  components: {},
  props: ['imgs'],
  data() {
    return {
      list: [],
      listIndex: 0, //默认显示第几张图片
      timer: null, //定时器
    };
  },
  filters:{
    getImgUrl(item){
       if(item && item.Image && item.Image.FilePath){
              return '/images?path='+item.Image.FilePath
       }else{
             return require("@/assets/imgs/grid.png")
       } 
    },
  },
  computed: {
    //上一张
    prevIndex() {
      if (this.listIndex == 0) {
        return this.imgs.length - 1;
      } else {
        return this.listIndex - 1;
      }
    },
    //下一张
    nextIndex() {
      // console.log('============------------------------====',this.imgs.length ,this.imgs.length - 1);
      if (this.listIndex == (this.imgs.length - 1)) {
        return 0;
      } else {
        return this.listIndex + 1;
      }
    },
  },
  methods: {
    changePage(index) {
      // console.log('======================',index)
      this.listIndex = index;
      this.$emit('changeHandel',index)
    },
    //移除
    out() {
      this.setTimer();
    },
    //移入
    over() {
      clearInterval(this.timer);
    },
    //1秒切图
    setTimer() {
      this.timer = setInterval(() => {
        this.listIndex++;
        if (this.listIndex == this.list.length) {
          this.listIndex = 0;
        }
      }, 1000);
    },
  },
  created() {
    //定时器
    // this.setTimer();
  },
  mounted() { },
};
</script>
<style scoped lang="less">
.home {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .box {
    position: relative;
    width: 100%;
    // height: 334px;
    background-image: url('../assets/imgs/grid.png');
    background-size: cover;
    img {
      width: 100%;
      height: 100%;
      z-index: 100;
    }

    .left {
      position: absolute;
      top: calc(50% - 25px);
      left: 0;
      cursor: pointer;
      color: white;
      font-size: 28px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50px;
      height: 50px;
      background: rgba(0, 0, 0, 0.5);
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }

    .right {
      position: absolute;
      top: calc(50% - 25px);
      right: 0;
      cursor: pointer;
      color: white;
      font-size: 28px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50px;
      height: 50px;
      background: rgba(0, 0, 0, 0.5);
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;

    } 

    ul {
      list-style: none;
      display: flex;
      justify-content: space-around;
      align-items: center;
      position: absolute;
      width: 150px;
      height: 20px;
      top: 103%;
      left: calc(50% - 75px);

      .color {
        background: #0090FF;
        color: #0090FF;
      }

      li {
        cursor: pointer;
        width: 10px;
        height: 10px;
        background: #E1E1E1;
        border-radius: 50%;
      }
    }
  }
}
</style>